$light-color: #ffffff;
$dark-color: #333333;
$main-color: #42b883;
$secondary-color: #35495e;
$background-color: #f8f8f8;
$third-color: #e96900;

$button-background-color: $main-color;
$button-border-color: $main-color;
// $button-disabled-text-color: $gray-light
$button-disabled-border-color: $dark-color;

$form-color-primary: $main-color;
$form-color-secondary: $secondary-color;

// Highlight colors
$base:    #fafafa;
$mono-1:  #383a42;
$mono-2:  #686b77;
$mono-3:  #a0a1a7;
$hue-1:   #0184bb;
$hue-2:   #4078f2;
$hue-3:   #a626a4;
$hue-4:   #50a14f;
$hue-5:   #e45649;
$hue-5-2: #c91243;
$hue-6:   #986801;
$hue-6-2: #c18401;

@import "~frow/variables";
