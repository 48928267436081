













code {
  display: block;
  padding: 0;
  overflow: visible;
  margin: 0; }
pre {
  display: block;
  overflow: visible;
  padding: 0;
  white-space: pre;
  word-wrap: normal; }
