



















@import "./variables";

.founder-image {
  border-radius: 50%;
  width: 290px;
  height: 290px;
  margin: 0 auto; }

.founder-name {
  text-align: center;
  margin-top: 16px;
  font-size: 38px;
  line-height: 120%; }

.founder-twitter {
  display: block;
  text-align: center; }

.links {
  margin-top: 90px;
  border-top: 1px solid #757575;
  max-width: 225px;
  margin: 30px auto 0px;
  padding-top: 30px;
  a {
    display: block;
    text-align: center; } }

.founder-title {
  text-align: center;
  font-size: 17px;
  font-style: italic; }
