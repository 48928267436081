@import "./variables";

code {
  border-radius: 3px;
  background: $base; }

pre code {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: $mono-1;
  background: $base; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #999; }

.namespace {
  opacity: .7; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, .5); }

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a; }

.token.function,
.token.class-name {
  color: #DD4A68; }

.token.regex,
.token.important,
.token.variable {
  color: #e90; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

// .hljs-comment,
// .hljs-quote
//   color: $mono-3
//   font-style: italic

// .hljs-doctag,
// .hljs-keyword,
// .hljs-formula
//   color: $hue-3

// .hljs-section,
// .hljs-name,
// .hljs-selector-tag,
// .hljs-deletion,
// .hljs-subst
//   color: $hue-5

// .hljs-literal
//   color: $hue-1

// .hljs-string,
// .hljs-regexp,
// .hljs-addition,
// .hljs-attribute,
// .hljs-meta-string
//   color: $hue-4

// .hljs-built_in,
// .hljs-class .hljs-title
//   color: $hue-6-2

// .hljs-attr,
// .hljs-variable,
// .hljs-template-variable,
// .hljs-type,
// .hljs-selector-class,
// .hljs-selector-attr,
// .hljs-selector-pseudo,
// .hljs-number
//   color: $hue-6

// .hljs-symbol,
// .hljs-bullet,
// .hljs-link,
// .hljs-meta,
// .hljs-selector-id,
// .hljs-title
//   color: $hue-2

// .hljs-emphasis
//   font-style: italic

// .hljs-strong
//   font-weight: bold

// .hljs-link
//   text-decoration: underline
