//
// Frow Variables
// --------------------------------------------------

//== Feature Toggles
//
//## Select the Frow features needed

//** Basics (anchor links, h1-h6, etc.)
$frow-enable-html-basics:                true !default;

//** Columns
$frow-enable-columns:                    true !default;

//** Form elements
$frow-enable-buttons:                    true !default;
$frow-enable-forms:                      true !default;

//** Responsive design
$frow-enable-responsive-design-classes:  true !default;

//** Utilities
$frow-enable-box-model-shorcuts:         true !default;
$frow-enable-utilities:                  true !default;

//** Normalize.css
$frow-enable-normalize:                  true !default;



//== Colors
//
//## Gray and colors for use across Frow

$gray-lighter: #EAEAEA !default;
$gray-light: #9E9E9E !default;
$gray-dark: #888888 !default;
$gray-darker: #4F4F51 !default;

$off-white: #BBB !default;
$off-black: #333 !default;

$white: #FFF !default;
$black: #000 !default;



//== Scaffolding
//
//## Settings for some of the most global styles

//** Background color
$background-color: #F6F6F6 !default;

//** Global text color on `<body>`
$text-color: $off-black !default;

//** Global textual link color
$link-color: $off-black !default;

//** Link hover color set via `darken()` function
$link-hover-color: darken($link-color, 15%) !default;



//== Button colors
//
//## Define the various button colors

//** Global button text color
$button-text-color: $white !default;

//** Global button background color
$button-background-color: $gray-darker !default;

//** Global button border color
$button-border-color: $gray-darker !default;

//** Global button disabled text color
$button-disabled-text-color: $gray-dark !default;

//** Global button disabled background/border color
$button-disabled-border-color: $gray-lighter !default;

//** Button active click size
$button-active-size: 0.96 !default;



//== Form element colors
//
//## Define various form element colors

//** Form primary color
$form-color-primary: $gray-darker !default;

//** Form light color
$form-color-secondary: $gray-light !default;

//** Form placeholder color
$form-placeholder-color: $gray-light !default;

//** Form focus size
$form-focus-size: 1.15 !default;

//** Checkmark color
$form-checkmark-color: $white !default;



//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes

//** Small screen / tablet (These assume column 1-1)
$sizes-mobile: (xs: 0px, sm: 768px) !default;

//** Desktops / laptops
$sizes-desktop: (md: 992px, lg: 1200px) !default;



//== Grid system
//
//## Define your custom responsive grid

//** Number of max columns in the grid
$grid-max-columns: 12 !default;

//** Padding between columns. Gets divided in half for the left and right
$grid-gutter-width: 30px !default;



//== Box Shadows
//
//## Define shadow for material style cards

//** Shadow design for light backgrounds
$shadow-light: 0px 1px 3px 0px #A0A0A0 !default;

//** Shadow design for dark backgrounds
$shadow-dark: 0px 1px 3px 0px #1B1B1B !default;



//== Animations
//
//## Define speed for animations

//** Default Animation speed
$animate-speed: 0.4s !default;

//** Fast Animation speed
$animate-speed-fast: 0.1s !default;
