


































































@import "./variables";

.controls {
  margin-bottom: 50px;
  max-width: 550px; }

.controls-container {
  position: relative;

  button {
    margin: 7px;
    opacity: 0.5;
    position: absolute;
    &:hover {
      opacity: 1;
      transition: opacity $animate-speed; } }

  .toggle-code {
    right: 0;
    top: 0; } }

.code-controls {
  background-color: darken($base, 10%);
  padding-top: 7px;

  button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: darken($base, 30%);
    background-color: darken($base, 30%);

    &:disabled {
      background: none;
      border-color: $base;
      background-color: $base; } } }

h5 {
  font-family: "Tajawal";
  margin: 0;
  padding: 0; }

.name-container {
  margin: 10px 0; }

.code-container {
  // overflow: auto
  background-color: $base;
  padding: 15px; }

.file-container {
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
